import axios from 'axios'
import axiosRetry from 'axios-retry'

const axiosRef = axios.create({
  baseURL: 'https://api.dev.tatrysuperski.bergregions.pl/api'
})

axiosRetry(axiosRef, {
  retries: 10,
  retryDelay: () => 3000
})

const API = {
  fetchWidget: ({ id, skiStationId, weatherStationId }: { id: string; skiStationId?: string; weatherStationId?: string }) => {
    return axiosRef.get(`/external/v1/widgets/${id}`, {
      params: {
        skiStationId,
        weatherStationId
      }
    })
  }
}

export default API
