import { useContext } from 'react'

import { WidgetContext } from '@/context/WidgetContext'
import API from '@/lib/api'

const useWidget = () => {
  const { state, dispatch } = useContext(WidgetContext)

  const fetchWidget = ({ skiStationId }: { skiStationId?: string }) => {
    dispatch({ type: 'SET_STATUS', payload: 'LOADING' })
    return new Promise(resolve => {
      API.fetchWidget({ id: '63850ae7c471ed4d47f175fb', skiStationId })
        .then(response => {
          dispatch({ type: 'SET_PARAMS', payload: response.data })
          dispatch({ type: 'SET_STATUS', payload: 'LOADED' })
          resolve(response.data)
        })
        .catch(() => {
          dispatch({ type: 'SET_STATUS', payload: 'ERROR' })
        })
    })
  }

  return {
    ...state,
    fetchWidget,
    openNavigation: () => dispatch({ type: 'OPEN_NAVIGATION' }),
    setSelectedStation: (payload: any) => dispatch({ type: 'SET_SELECTED_STATION', payload }),
    setCurrentTab: (payload: any) => dispatch({ type: 'SET_CURRENT_TAB', payload }),
    setCurrentView: (payload: any) => dispatch({ type: 'SET_CURRENT_VIEW', payload }),
    closeNavigation: () => dispatch({ type: 'CLOSE_NAVIGATION' })
  }
}

export default useWidget
