import styled from 'styled-components'

import { LastMb0 } from './../../lib/mixins'

export const HeaderWrapper = styled.div`
  position: relative;
  height: 16.5rem;
  display: flex;
  align-items: center;
  padding: 0 ${props => props.theme.layout.outerMargin};
  flex-shrink: 0;
`

export const HeaderUtils = styled.div`
  position: absolute;
  top: 1rem;
  right: 1.65rem;
  display: flex;
  align-items: center;
`

export const HeaderLogo = styled.div`
  width: 10rem;
  height: 10rem;
  background-color: white;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;

  img {
    max-width: 80%;
    max-height: 80%;
    object-fit: contain;
    display: block;
  }
`

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  h1 {
    margin-right: 1.5rem;
    margin-bottom: 0;
  }
`

export const HeaderWeather = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 2rem;
  margin-right: 2rem;

  &:after {
    position: absolute;
    top: 10%;
    right: 0;
    content: ' ';
    height: 80%;
    width: 3px;
    background: ${props => props.theme.color.line};
    transform: rotate(45deg);
  }

  > svg {
    height: 3.25rem;
    width: 3.5rem;
    margin-right: 0.5rem;
  }

  > span {
    font-size: 1.75rem;
    font-weight: 300;

    > sup {
      font-size: 0.65em;
      font-weight: 400;
      margin-left: -0.25em;
      top: -0.4em;
      position: relative;
    }
  }
`

export const HeaderTime = styled.div`
  font-weight: 300;
  font-size: 1.75rem;
`

export const HeaderBody = styled.div``

export const HeaderContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  h1 {
    margin-bottom: 0;
  }
`
