import styled, { css } from 'styled-components'

import { TransitionSecondary } from './../../lib/mixins'

export const BackgroundWrapper = styled.div<{ disabled: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  width: 65vw;
  height: 60vh;
  opacity: 0.5;
  mask-image: linear-gradient(to bottom, black 75%, transparent);
  z-index: -1;
  ${TransitionSecondary('transform')}

  ${props =>
    props.disabled &&
    css`
      transform: scale(1.1);
      filter: blur(5px);
    `};

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    mask-image: radial-gradient(closest-corner at 58% 59%, black 0% 70%, transparent);
  }
`
