import { useTranslation } from 'react-i18next'

import Icon from '@/components/Icon'
import useWidget from '@/hooks/useWidget'
import { getIcon } from '@/lib/weather'

import {
  WeatherBox,
  WeatherCurrentDetail,
  WeatherCurrentDetailContent,
  WeatherCurrentDetails,
  WeatherCurrentMain,
  WeatherCurrentMainCaption,
  WeatherCurrentMainContent,
  WeatherCurrentMainTemperature,
  WeatherCurrentTitle
} from './Weather.styles'

function WeatherCurrent() {
  const {
    params: { weatherFields, weather }
  } = useWidget()
  const { t } = useTranslation()

  return (
    <WeatherBox>
      <WeatherCurrentTitle>{t('weather.current')}</WeatherCurrentTitle>
      <WeatherCurrentMain>
        <Icon name={getIcon(weather.weatherCode)} namespace={'weather/day/'}></Icon>
        <WeatherCurrentMainContent>
          <WeatherCurrentMainTemperature>
            {weather.temperature} <sup>°C</sup>
          </WeatherCurrentMainTemperature>
          <WeatherCurrentMainCaption>
            <span>{t('weather.perceivedTemperature')}</span>
            <strong>
              {weather.perceivedTemperature}
              <sup>°C</sup>
            </strong>
          </WeatherCurrentMainCaption>
        </WeatherCurrentMainContent>
      </WeatherCurrentMain>
      <WeatherCurrentDetails>
        {/* Precipitation */}
        {weatherFields.includes('precipitation') && (
          <WeatherCurrentDetail>
            <Icon name={'rain'} namespace={'weather/day/'}></Icon>
            <WeatherCurrentDetailContent>
              <span>
                {t('weather.precipitationFrom')} {weather.precipitationPeriod} {t('weather.hoursUnit')}
              </span>
              <strong>
                {weather.precipitationType === 'SNOW' ? t('weather.snow') : t('weather.rain')}, {weather.precipitation} cm
              </strong>
            </WeatherCurrentDetailContent>
          </WeatherCurrentDetail>
        )}
        {/* Humidity */}
        {weatherFields.includes('humidity') && (
          <WeatherCurrentDetail>
            <Icon name={'humidity'} namespace={'weather/day/'}></Icon>
            <WeatherCurrentDetailContent>
              <span>{t('weather.humidity')}</span>
              <strong>{weather.humidity || '-'}%</strong>
            </WeatherCurrentDetailContent>
          </WeatherCurrentDetail>
        )}
        {/* Wind */}
        {(weatherFields.includes('windDirection') || weatherFields.includes('windStrength')) && (
          <WeatherCurrentDetail>
            <Icon name={'wind'} namespace={'weather/day/'}></Icon>
            <WeatherCurrentDetailContent>
              <span>{t('weather.wind')}</span>
              <strong>
                {weatherFields.includes('windDirection') && <>{t(`directions.${weather.windDirection}`)}</>},
                {weatherFields.includes('windStrength') && <>{weather.windStrength || '-'} km/h</>}
              </strong>
            </WeatherCurrentDetailContent>
          </WeatherCurrentDetail>
        )}
        {/* Atmospheric Pressure */}
        {weatherFields.includes('atmosphericPressure') && (
          <WeatherCurrentDetail>
            <Icon name={'fog'} namespace={'weather/day/'}></Icon>
            <WeatherCurrentDetailContent>
              <span>{t('weather.atmosphericPressure')}</span>
              <strong>{weather.atmosphericPressure || '-'} hPa</strong>
            </WeatherCurrentDetailContent>
          </WeatherCurrentDetail>
        )}
        {/* Sun */}
        {weatherFields.includes('sun') && (
          <WeatherCurrentDetail>
            <Icon name={'sunny'} namespace={'weather/day/'}></Icon>
            <WeatherCurrentDetailContent>
              <span>{t('weather.hoursOfSun')}</span>
              <strong>
                {weather.hoursOfSun} {t('weather.hoursUnit')}
              </strong>
            </WeatherCurrentDetailContent>
          </WeatherCurrentDetail>
        )}
      </WeatherCurrentDetails>
    </WeatherBox>
  )
}

export default WeatherCurrent
