import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'

import Badge from '@/components/Badge'
import Icon from '@/components/Icon'
import useWidget from '@/hooks/useWidget'
import { getIcon } from '@/lib/weather'

import { HeaderBody, HeaderContent, HeaderLogo, HeaderTime, HeaderTitle, HeaderUtils, HeaderWeather, HeaderWrapper } from './Header.styles'

function Header() {
  const now = new Date()
  const { t } = useTranslation()
  const {
    params: { skiStationName, skiStationLogo, skiStationOpen, weather }
  } = useWidget()

  return (
    <HeaderWrapper>
      <HeaderUtils>
        {/* Weather */}
        {weather && (
          <HeaderWeather>
            <Icon name={getIcon(weather.weatherCode)} namespace={'weather/day/'}></Icon>
            <span>
              {weather.temperature} <sup>°C</sup>
            </span>
          </HeaderWeather>
        )}

        {/* Time */}
        <HeaderTime>
          <Moment date={now} interval={30000} format="HH:mm" />
        </HeaderTime>
      </HeaderUtils>
      <HeaderBody>
        <HeaderContent>
          {skiStationLogo && (
            <HeaderLogo>
              <img src={skiStationLogo} alt={skiStationName} />
            </HeaderLogo>
          )}
          <HeaderTitle>
            <h1>{skiStationName}</h1>
            <Badge variant={skiStationOpen ? 'green' : 'muted'}>
              {skiStationOpen ? t('skiStation.status.open') : t('skiStation.status.closed')}
            </Badge>
          </HeaderTitle>
        </HeaderContent>
      </HeaderBody>
    </HeaderWrapper>
  )
}

export default Header
