/* eslint @typescript-eslint/no-var-requires: "off" */
import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import SVG from 'react-inlinesvg'
import { useLocation } from 'react-router-dom'

import { EnterPressHandler, FocusContext, FocusHandler, useFocusable } from '@noriginmedia/norigin-spatial-navigation'

import useWidget from '@/hooks/useWidget'

import { useViewScroll } from '../View'
import {
  NavigationBackdrop,
  NavigationItemWrapper,
  NavigationLink,
  NavigationList,
  NavigationLogo,
  NavigationWrapper
} from './Navigation.styles'

interface NavigationItemProps {
  id: string
  title: string
  name?: string
  path?: string
  extended: boolean
  active: boolean
  index: number
  onEnterPress: EnterPressHandler | undefined
  onFocus: FocusHandler | undefined
}

function NavigationItem({ title, name, extended, index, active, onEnterPress, onFocus }: NavigationItemProps) {
  const { focused, ref, focusSelf } = useFocusable({ trackChildren: true, onEnterPress, onFocus, focusKey: `NAVIGIATION_ITEM_${index}` })

  useEffect(() => {
    if (active) {
      focusSelf()
    }
  }, [])

  return (
    <NavigationItemWrapper ref={ref}>
      <NavigationLink focused={focused} focusKey={name} extended={extended} active={active}>
        <span>{title}</span>
      </NavigationLink>
    </NavigationItemWrapper>
  )
}

function Navigation() {
  const {
    isNavigationOpen,
    openNavigation,
    closeNavigation,
    setSelectedStation,
    fetchWidget,
    selectedStation,
    params: { availableStations, id }
  } = useWidget()
  const { scrollTop } = useViewScroll()
  const { ref, focusKey, setFocus, hasFocusedChild } = useFocusable({
    focusable: true,
    trackChildren: true,
    focusKey: 'NAVIGATION',
    onFocus() {
      openNavigation()
      window.addEventListener('keydown', handleKeyDown)
    },
    onBlur() {
      closeNavigation()
      window.removeEventListener('keydown', handleKeyDown)
    }
  })
  const scroller = useRef(null)

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'ArrowRight') {
      closeNavigation()
    }
  }
  const handleStationChange = (station: any) => {
    fetchWidget({
      skiStationId: station.id
    }).then(() => {
      setSelectedStation(station)
    })
  }

  const handleItemFocus = useCallback(({ y }: { y: number }) => {
    if (scroller?.current) {
      const el: any = scroller.current
      let yTarget = y

      if (y <= window.innerHeight * 0.75) {
        yTarget = 0
      } else {
        yTarget = y * 0.9
      }

      el.scrollTo({
        top: yTarget - window.innerHeight * 0.2,
        behavior: 'smooth'
      })
    }
  }, [])

  useEffect(() => {
    closeNavigation()
    scrollTop()
  }, [selectedStation])

  useEffect(() => {
    if (!isNavigationOpen) {
      setFocus('TABS')
    }
  }, [isNavigationOpen])

  return (
    <>
      <NavigationBackdrop show={isNavigationOpen} />
      <FocusContext.Provider value={focusKey}>
        <NavigationWrapper ref={ref} extended={isNavigationOpen} focused={hasFocusedChild}>
          <NavigationLogo>
            <SVG src={require('@/assets/img/logo-full.svg').default} />
          </NavigationLogo>
          <NavigationList ref={scroller}>
            {availableStations.map((item: any, index: number) => (
              <NavigationItem
                active={selectedStation.id === item.id}
                id={item.id}
                key={index}
                index={index}
                title={item.name}
                extended={isNavigationOpen}
                onFocus={handleItemFocus}
                onEnterPress={() => handleStationChange(item)}
              ></NavigationItem>
            ))}
          </NavigationList>
        </NavigationWrapper>
      </FocusContext.Provider>
    </>
  )
}

export default Navigation
