import styled, { css } from 'styled-components'

import { TransitionPrimary, TransitionSecondary } from './lib/mixins'

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  display: flex;
  ${TransitionPrimary('background')}
`

export const Main = styled.div<{ disabled?: boolean }>`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  ${TransitionSecondary('transform')}

  ${props =>
    props.disabled &&
    css`
      transform: scale(0.975);
      filter: blur(5px);
    `};
`

export const BuildInfo = styled.div`
  position: absolute;
  z-index: 10000;
  left: 0.5rem;
  bottom: 0.5rem;
  font-size: 0.6rem;
  opacity: 0.5;
  letter-spacing: 0.1em;
  z-index: 100000;
`
